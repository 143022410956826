.pin-container {
  position: absolute;
  transition: 0.5s;
  pointer-events: none;
}

.pin {
  background-color: white;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  transition: 0.5s;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: transparent;
}

.pin.active {
  width: 120px;
  color: #707070;
}

.pin-stick {
  background-color: white;
  width: 2px;
  height: 32px;
  position: absolute;
  left: 15px;
}

.pin-text {
  text-align: center;
}

.pin-letter {
  position: absolute;
  color: transparent;
  transition: 0.5s;
}
.pin-letter.active {
  color: #707070;
}

/*---------------------------- Specific */

.pin-container.block-b {
  left: 710px;
  top: 500px;
}

.pin-container.block-d {
  left: 940px;
  top: 500px;
}

.pin-container.block-f {
  left: 1162px;
  top: 500px;
}

.pin-container.block-a {
  left: 964px;
  top: 730px;
}

.pin-container.block-c {
  left: 1192px;
  top: 730px;
}

.pin-container.block-e {
  left: 1420px;
  top: 730px;
}

.pin-container.block-h {
  left: 1470px;
  top: 430px;
}

.pin-container.block-r {
  left: 560px;
  top: 690px;
}

.pin-container.block-p {
  left: 510px;
  top: 290px;
}

.pin-container.ru {
  left: 860px;
  top: 240px;
}

.pin-container.bus {
  left: 290px;
  top: 407px;
}

.pin-container.stand {
  left: 440px;
  top: 490px;
}

.pin-container.sponsor {
  left: 1003px;
  top: 315px;
}
