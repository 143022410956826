body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.global-shadow {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.4);
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Auto-Zoom for high-res screens */
@media only screen and (min-height: 1080px) {
  html {
    zoom: 1; /* fallback */
    zoom: calc(var(--screen-y) / 1080);
  }
}

/* Fixes for Ultra-Wide 1080p monitors */
@media only screen and (min-height: 1080px) and (min-width: 1920px) {
  html {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .components-overlay {
    max-width: 1920px !important;
  }
}
