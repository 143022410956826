.sponsor-container .card {
  display: flex;
  flex-direction: column;
}

.sponsor-container img {
  cursor: pointer;
  margin: 10px;
  padding: 5%;
  border-radius: 16px;
  max-height: 300px;
  object-fit: contain;
}

.sponsor-container h2 {
  margin-left: 8px;
}
