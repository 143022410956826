.welcome-container {
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #00000052;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: 1s;

  pointer-events: auto;
}

.welcome-panel {
  width: 720px;
  height: 400px;
  background-color: white;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.welcome-panel img {
  width: 90%;
}

/** TUTORIAL */

.welcome-panel .tutorial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-panel .tutorial-container > * {
  margin: 2px;
  text-align: center;
}

.welcome-panel .tutorial-container .cards {
  display: flex;
  justify-content: center;
}

.welcome-panel .tutorial-container .arrow {
  width: 32px;
  height: 32px;

  background-color: white;
  border-radius: 50%;
  padding: 8px;
  margin: 8px;
  align-self: center;

  cursor: pointer;
}

.welcome-panel .tutorial-container .arrow-right {
  transform: scaleX(-1);
}

.welcome-panel .tutorial-container .card-img {
  height: 300px;
  object-fit: contain;
}

.welcome-panel h3 {
  font-size: 16pt;
  color: grey;
}

@media only screen and (max-width: 720px) {
  .welcome-panel {
    width: 90%;
  }

  .welcome-panel .tutorial-container .arrow {
    position: absolute;
    align-self: flex-end;
    left: 64px;
  }

  .welcome-panel .tutorial-container .arrow-right {
    position: absolute;
    left: auto;
    right: 64px;
  }
}
