.city-tour {
  background-image: url("../../components/map/background.jpg");
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.city-tour .components-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
