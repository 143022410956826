.components-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.fork-me {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0;
  display: flex;
  margin: 16px;
  z-index: 1;
}

@media only screen and (max-width: 720px) {
  .fork-me {
    display: none;
  }
}
