.big-container {
  width: 90%;
  /* height: 70%; */
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 1000px;

  background-color: white;
  border-radius: 16px;

  display: flex;
  flex-direction: column;

  pointer-events: auto;
  overflow: auto;

  position: relative;
}

.highlights-container {
  margin: 16px;
}

.big-container .ic-close {
  width: 32px;
  height: 32px;
  margin: 8px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background-color: transparent;
  border-radius: 16px;
}

/* Tabs */

.highlights-container .tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.highlights-container .tab {
  height: 32px;
  width: 100px;
  border: 0;
  margin: 4px;
  border-radius: 8px;
  background-color: #e2e2e1;
  cursor: pointer;
}

.highlights-container .active {
  background-color: #d0e49e;
}

/* Highlights */

.highlights-container .times {
  width: 140px;
  padding: 8px;
  background-color: #d0e49e;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.highlights-container .times img {
  width: 16px;
  height: 16px;
}

.highlights-container .row {
  display: flex;
  margin-top: 24px;
  font-size: 11pt;
}

.highlights-container .row .photo {
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: white;
  margin-left: 32px;
  margin-right: 16px;
  object-fit: cover;

  -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
}

.highlights-container .row .activity-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.highlights-container .row .activity-info a {
  color: #395b94;
}

.highlights-container .row .activity-info .title {
  font-weight: bold;
}

.highlights-container .row .activity-info .abstract-link {
  display: flex;
  flex-direction: row;
}

.highlights-container .row .activity-info .abstract-link span {
  color: grey;
  margin-left: 16px;
  cursor: pointer;
}

.abstract-modal-container {
  height: 100%;
  width: 100%;
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 50px;
  box-sizing: border-box;
  overflow: scroll;
}

.abstract-modal {
  max-width: 720px;
  max-height: 500px;
  overflow: auto;
  background-color: white;
  border-radius: 16px;
  padding: 5%;
  position: absolute;
}

@media only screen and (max-width: 720px) {
  .highlights-container .row .photo {
    margin-left: 0;
  }

  .highlights-container .row .activity-info .abstract-link {
    flex-direction: column;
  }

  .highlights-container .row .activity-info .abstract-link span {
    margin-left: 0;
  }

  .highlights-container .tabs {
    margin-top: 24px;
  }
}
