.templates-container {
  display: flex;
  flex-direction: column;
}

.templates-container .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.templates-container .card {
  display: flex;
  margin: 16px;
  border-radius: 8px;
  padding: 16px;
  min-width: 180px;
}

.templates-container .card img {
  height: 48px;
  margin-right: 16px;
}

.templates-container .card a {
  text-decoration: none;
  color: grey;
  display: flex;
  align-items: center;
}
