.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact > *:first-child {
  align-self: flex-start;
}

.contact p {
  text-align: center;
}

.contact span {
  width: 100%;
  text-align: center;
}

.contact a {
  font-size: 1.6em;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 32px;

  color: rgb(64 127 182);
}

.contact a > *:first-child {
  margin-right: 8px;
}

.contact .social {
  display: flex;
  font-size: 1.5em;
  flex-wrap: wrap;
  justify-content: center;
}

.contact .social > * {
  color: grey;
  margin: 32px;
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .contact a {
    font-size: 1.2em;
    margin-left: 0;
    margin-right: 0;
  }
}
