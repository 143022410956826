.modal {
  width: 720px;
  background-color: white;
  border-radius: 16px;
  /* transition: 0.5s; */

  overflow: auto;
  margin-top: 8px;
  margin-bottom: 8px;

  pointer-events: auto;
  opacity: 1;
  position: relative;
}

.modal.hidden {
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.modal.ic-close {
  width: 32px;
  height: 32px;
  margin: 8px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
}

.modal.content {
  width: 89%;
  height: 100%;
  margin: auto;
  background-color: transparent;
  overflow: auto;
  border-radius: 0;
}

.modal-fullscreen-background {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: auto;
}

@media only screen and (max-width: 720px) {
  .modal {
    width: 90%;
  }
}
