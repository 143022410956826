.topmenu {
  width: 480px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  pointer-events: auto;
}

.topmenu .logo {
  width: 64px;
  height: 64px;
  margin: 16px;
}

.topmenu .title {
  flex-grow: 1;
  font-size: 2em;
}

.topmenu .right-options {
  width: 64px;
  height: 64px;
  margin: 16px;
  position: relative;
}

.topmenu .divider {
  width: 2px;
  border-radius: 2px;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  position: absolute;
  left: -16px;
}

.topmenu .ic-menu {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: inherit;
}

@media only screen and (max-width: 600px) {
  .topmenu {
    width: 90%;
  }

  .topmenu .title {
    font-size: 5vw;
  }

  .topmenu .divider {
    display: none;
  }
}
