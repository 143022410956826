.map-overlay {
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  transition: 0.5s;
  opacity: 0;
}

.map-overlay:hover {
  opacity: 1;
}

/*---------------------------- Specific */

.map-overlay.block-b {
  background-image: url(./overlay-bloco-1.png);
  width: 317px;
  height: 266px;
  left: 582px;
  top: 468px;
}

.map-overlay.block-d {
  background-image: url(./overlay-bloco-1.png);
  width: 317px;
  height: 266px;
  left: 804px;
  top: 462px;
}

.map-overlay.block-f {
  background-image: url(./overlay-bloco-1.png);
  width: 317px;
  height: 266px;
  left: 1022px;
  top: 454px;
}

.map-overlay.block-a {
  background-image: url(./overlay-bloco-2.png);
  width: 315px;
  height: 276px;
  left: 812px;
  top: 662px;
}

.map-overlay.block-c {
  background-image: url(./overlay-bloco-2.png);
  width: 315px;
  height: 276px;
  left: 1034px;
  top: 654px;
}

.map-overlay.block-e {
  background-image: url(./overlay-bloco-2.png);
  width: 315px;
  height: 276px;
  left: 1244px;
  top: 644px;
}

.map-overlay.block-h {
  background-image: url(./overlay-bloco-3.png);
  width: 315px;
  height: 310px;
  left: 1336px;
  top: 400px;
}

.map-overlay.block-r {
  background-image: url(./overlay-bloco-4.png);
  width: 566px;
  height: 305px;
  left: 210px;
  top: 626px;
}

.map-overlay.block-p {
  background-image: url(./overlay-bloco-p.png);
  width: 477px;
  height: 235px;
  left: 295px;
  top: 275px;
}

.map-overlay.ru {
  background-image: url(./overlay-ru.png);
  width: 251px;
  height: 168px;
  left: 756px;
  top: 248px;
}

.map-overlay.bus {
  background-image: url(./overlay-bus.png);
  width: 98px;
  height: 76px;
  left: 252px;
  top: 445px;
}

.map-overlay.stand {
  background-image: url(./overlay-stand.png);
  width: 133px;
  height: 112px;
  left: 404px;
  top: 511px;
}

.map-overlay.sponsor {
  background-image: url(./overlay-sponsor.png);
  width: 122px;
  height: 80px;
  left: 958px;
  top: 362px;
}
