.modal .content span {
  color: grey;
  font-size: 16pt;
  margin-bottom: 32px;
}

.modal .content h1 {
  font-size: 1.8em;
}

.modal .content h2 {
  font-size: 1.5em;
  color: #707070;
}

.modal .content .link-list {
  display: flex;
  flex-direction: column;
}

.modal .content .link {
  font-size: 1.2em;
  color: grey;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal .content .link svg {
  margin-right: 8px;
}

.modal .content > *:last-child {
  margin-bottom: 20px;
}

.video {
  width: 98%;
  border-radius: 16px;
  margin-bottom: 32px;
}

.single-image {
  width: 98%;
  border-radius: 16px;
  margin-bottom: 32px;
}

.ic-evin {
  height: 17px;
  margin-right: 8px;
}

.anais a {
  font-size: 1.1em;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 32px;

  color: rgb(64 127 182);
}

.anais a > *:first-child {
  margin-right: 8px;
}
