.table-container {
  width: 90%;
  height: 90%;

  margin-top: 8px;
  margin-bottom: 8px;

  background-color: white;
  border-radius: 16px;

  display: flex;
  flex-direction: column;

  pointer-events: auto;
  overflow: auto;

  position: relative;
}

.table-container .ic-close {
  width: 32px;
  height: 32px;
  margin: 8px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background-color: transparent;
  border-radius: 16px;
}

.table-container .table-container .filters {
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.table-container .pagination {
  margin: 16px;
}

.table-wrap {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

table {
  border-radius: 8px;
  margin: 16px;
  border-spacing: 0;
  /* border: 1px solid rgb(137 133 121 / 50%); */
  border-collapse: collapse;
}

th,
td {
  margin: 0;
  padding: 0.36rem;
  border-bottom: 1px solid rgb(137 133 121 / 30%);
  border-right: 1px solid rgb(137 133 121 / 30%);
}

th:last-child,
td:last-child {
  border-right: 0;
}

tr:last-child td {
  border-bottom: 0;
}

table input {
  width: 95%;
}

table a {
  color: #395b94;
  text-decoration: none;
}

table .ic-conference {
  height: 12px;
  margin-right: 4px;
}

.table-container h2 {
  font-size: 1.5em;
  color: #707070;
  align-self: center;
  text-align: center;
}

.temp-content {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
}

@media only screen and (max-width: 720px) {
  .table-container .ic-close {
    margin: 2px;
  }
}

.react-table-header-th {
  vertical-align: bottom;
  padding: 0;
  border-left: 0;
  border-right: 0;
}

.react-table-header-filterdiv input {
  width: 100%;
  height: 32px;
  padding: 8px;
  border: 0;
  outline: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #e4e4e4;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-table-header-filterdiv select {
  width: 100%;
  height: 32px;
  padding: 8px;
  border: 0;
  outline: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #e4e4e4;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.small-font-table {
  font-size: 11pt;
}
